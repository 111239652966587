import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["inputQuantity"];

    static values = {
        minQuantity: Number,
        currentQuantity: Number
    }

    quantity = 0;

    connect() {
        this.quantity = this.currentQuantityValue;
    }

    incrementQuantity() {
        this.quantity += this.minQuantityValue;
        this.inputQuantityTarget.value = this.quantity;
    }

    decrementQuantity() {
        if (this.quantity == this.minQuantityValue) {
            return;
        }

        if (this.quantity == 0) {
            this.quantity = this.minQuantityValue;
        } else {
            this.quantity -= this.minQuantityValue;
        }
        this.inputQuantityTarget.value = this.quantity;
    }
}