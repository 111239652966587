import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["entry", "adjustedPoints", "intermediateTotal", "newEstimatedBalance"];

    static values = {
        approvedBalance: Number,
        pointsUsed: Number,
        pointsEarned: Number,
    }

    connect() {
        console.log("Hello from adjust_points");
    }

    getValue(e) {
        e.preventDefault();

        /** calculs */
        let adjustedPoints = parseInt(this.entryTarget.value);

        let intermediateTotal = this.pointsUsedValue + this.pointsEarnedValue + adjustedPoints;

        let newEstimatedBalance = this.approvedBalanceValue + intermediateTotal;
        console.log(newEstimatedBalance);

        /** affichages */
        // nécessaire pour montrer la valeur convertie en entier
        this.entryTarget.value = adjustedPoints;

        this.adjustedPointsTarget.textContent = adjustedPoints;

        this.intermediateTotalTarget.textContent = intermediateTotal;

        this.newEstimatedBalanceTarget.textContent = newEstimatedBalance;
    }
}