/**
 * Ce contrôleur récupère les données depuis le localStorage
 * puis les envoie automatiquement à la page spécifiée dans action = 'app_bureautique_displayinktonersearchresults'
 * appelé depuis hidden_form_cart_transmit.html.twig
 */
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["inputField"]

    connect() {
        const cart = localStorage.getItem('cart');

        this.inputFieldTarget.setAttribute("value", cart);
        this.element.submit();
    }
}