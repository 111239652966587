/**
 * Ce controlleur sauvegarde le cart dans le localStorage
 * Il est a appelé depuis ink_toner_search.html.twig
 *
 */
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static values = {
        cart: Object
    }



    connect() {

        if (!this.cartValue.empty) {

            let cartToStore = JSON.stringify(this.cartValue);

            localStorage.setItem('cart', cartToStore);

        }
    }
}