import {Controller} from "@hotwired/stimulus";

/*
Ce controlleur gère le choix de la marque (via un select) sur la page d'affichage des résultats de la bureautique.
 */

export default class extends Controller {

    static targets = ['brandSelect'];

    connect() {
        console.log("Hey from search filter brands");
    }

    updateURL() {

        // récupère la valeur du sélect
        console.log(this.brandSelectTarget.value);

        // renvoie vers la route app_searchbureautique_handleselectbrandresult
        this.element.submit();
    }
}