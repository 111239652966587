import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ["trigger", "target"];

    connect() {
        console.log("Toggle-state");
    }

    update() {
        if (this.triggerTarget.checked) {
            this.targetTarget.classList.remove("disabled");
        } else {
            this.targetTarget.classList.add("disabled");
        }
    }
}