/**
 * Ce controlleur supprime le cart dans le localStorage
 * Il est a appelé depuis ink_toner_search_results.html.twig
 */
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {

        localStorage.removeItem('cart');
    }
}