import {Controller} from "@hotwired/stimulus";

/*
Ce controlleur gère le click sur un checkbox.
 */

export default class extends Controller {

    static targets = ['checkBoxes'];

    connect() {
        console.log("Hey from search check box controller");
    }

    updateURL() {

        // récupère la valeur du sélect
        // console.log(this.checkBoxesTarget.checked);

        // renvoie vers la route du check box
        this.element.submit();
    }
}