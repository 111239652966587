import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['selectButton'];

    valid() {
        this.element.submit();
    }
}
