/**
 * Permet de connecter Sortable à une liste (list-group)
 * Et de déplacer les éléments pour les réorganiser
 * Eléments :
 *  - container : la section qui englobe les éléments à trier
 *  - sortableItem : les éléments à trier (dans une loop, généralement avec la classe list-group-item. Rajouter un data-id
 *  - saving :
 */

import { Controller } from '@hotwired/stimulus';
import Sortable from "sortablejs";

export default class extends Controller {

    static targets = ['container', 'sortableItem', 'saving'];

    static values = {
        url: String,
    }

    connect() {

        if (this.containerTarget) {

            this.sortableItemTargets.map(item => item.classList.add("handle", "noselect"));

            Sortable.create(this.containerTarget, {
                // handle: '.handle',
                draggable: '.list-group-item',
                direction: 'vertical',
                forceFallback: true,
                onEnd: () => this.save(),
            });
        }
    }

    save() {
        this.savingTarget.classList.remove('d-none')

        const items = this.sortableItemTargets.map(item => item.dataset.id);
        console.log(items);
        const url = new URL(this.urlValue, document.baseURI);

        const request = new Request(url.toString(), {
            method: 'post',
            body: JSON.stringify(items),
        });

        fetch(request)
            .then(response => {
                return response.json();
            })
            .then(() => {
                this.savingTarget.classList.add('d-none');
            });
    }
}