/**
 * This controller is linked to admin_papers/prices_modification_by_group.html.twig
 */
import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
    static targets = ['nomButton', 'variationInput', 'tableResults', 'results', 'groupName'];

    static values = {
        url: String,
    }


    connect() {
        console.log('Hello from paper-price-group');
        // get url value
        const baseUrl = new URL(this.urlValue, document.baseURI);
        this.url = baseUrl.toString();
    }

    updateStatus(e) {
        const button = e.currentTarget;
        this.toggleStatus(button);
    }

    test() {
         let url =  this.url + '/test';
        console.log(url);
        this.handle(url);
    }

    submit() {
        let url =  this.url + '/live';
        this.handle(url);
    }

    handle(url) {

        // retrieve checked buttons
        let selectedNoms = this.nomButtonTargets.filter(item => {
            return item.dataset.buttonStatus === "checked";
        });

        if (selectedNoms.length === 0) {
            console.log('Pas de nom sélectionné');
            return;
        }

        let selectedNomsIds = selectedNoms.map(item => item.dataset.buttonId);

        // retrieve variation value
        let variationPrice = this.variationInputTarget.value || 0;

        if (variationPrice === 0) {
            console.log("Pas de variation");
            return;
        }

        // append them
        let data = {
            selectedNomsIds: selectedNomsIds,
            variationPrice: variationPrice
        }
        console.log(data);

        // get url value
        // const url = new URL(this.urlValue, document.baseURI);
        // console.log(this.url);

        const request = new Request(url, {
            method: 'post',
            body: JSON.stringify(data),
        });

        fetch(request)
            .then(response => {
                return response.json();
            })
            .then(response => {

                this.tableResultsTarget.classList.remove('d-none');

                this.resultsTarget.textContent = "";

                JSON.parse(response.result).map(result => {

                    let tr = document.createElement("tr");

                    for (let item of result) {
                        let td = document.createElement("td");
                        td.append(item);
                        tr.append(td);
                    }

                    this.resultsTarget.append(tr);

                    // clear data
                    this.clearData();
                });
            });
    }

    /**
     * Cette fonction sélectionne les noms de la sous catégorie
     */
    toggleNomsStatus(e) {
        console.log('toggle noms status')
        const button = e.currentTarget;

        this.toggleScategoryButtonStatus(button);

        let groupName = button.dataset.groupName;
        let buttonNoms = this.element.getElementsByClassName(groupName);
        for (let button of buttonNoms) {
            console.log(button);
            this.toggleStatus(button);
        }
    }


    updateScategoriesStatus(e) {
        console.log('udpate scategories status')
        const button = e.currentTarget;

        let groupName = button.dataset.groupName;
        console.log('scategory-' + groupName);

        // les boutons des sous catégories
        let buttonsScategories = this.element.getElementsByClassName('scategory-' + groupName);

        for (let buttonScategory of buttonsScategories) {
            this.toggleScategoryButtonStatus(buttonScategory);
        }


        // les boutons des noms appartenant à cette catégorie
        let buttonNoms = this.element.getElementsByClassName(groupName);

        for (let button of buttonNoms) {
            this.toggleStatus(button);
        }
    }

    toggleStatus(button) {
        let status = button.dataset.buttonStatus;

        if (status == "notChecked") {

            button.setAttribute('data-button-status', 'checked');
            button.classList.remove('btn-outline-secondary');
            button.classList.add('btn-success');

        } else { // 'checked"

            button.setAttribute('data-button-status', 'notChecked');
            button.classList.remove('btn-success');
            button.classList.add('btn-outline-secondary');

        }
    }

    toggleScategoryButtonStatus(buttonScategory) {
        let status = buttonScategory.dataset.buttonStatus;
        if (status == "notChecked") {

            buttonScategory.setAttribute('data-button-status', 'checked');
            buttonScategory.classList.remove('btn-outline-dark');
            buttonScategory.classList.add('btn-dark');

        } else { // 'checked"

            buttonScategory.setAttribute('data-button-status', 'notChecked');
            buttonScategory.classList.remove('btn-dark');
            buttonScategory.classList.add('btn-outline-dark');

        }
    }

    clearData() {
        // this.variationInputTarget.value = null;
    }
}
