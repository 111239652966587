import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["inputField"];

    resetField(event) {
        event.preventDefault();

        this.inputFieldTarget.setAttribute('value', 0)
    }
}